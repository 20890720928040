export class Accordions {
  constructor() {
    this.accordionWrappers = document.querySelectorAll('.js-accordion-wrapper');

    if (this.accordionWrappers.length) {
      this.init();
    }
  }

  init() {
    this.accordionWrappers.forEach((wrapper) => {
      wrapper.addEventListener('click', (e) => {
        const trigger = e.target.closest('.js-accordion-trigger');
        const content = wrapper.querySelector('.js-accordion-content');

        if (trigger && content) {
          this.toggleAccordion(wrapper, trigger, content);
        }
      });
    });
  }

  toggleAccordion(wrapper, trigger, content) {
    wrapper.classList.toggle('opened');
    content.classList.toggle('displayed');
    trigger.setAttribute('aria-expanded', 'true');
  }
}
