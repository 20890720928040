export class CustomerNav {
  constructor() {
    this.customerNav = document.querySelector('.js-customer-menu');

    if (this.customerNav) {
      this.init();
    }
  }

  init() {
    this.trigger = this.customerNav.querySelector('.js-customer-menu-trigger');
    this.submenu = this.customerNav.querySelector('.js-customer-submenu');

    if (this.trigger && this.submenu) {
      this.trigger.addEventListener('click', () => {
        this.toggleSubmenu();
      });

      document.addEventListener('click', (e) => {
        if (!e.target.closest('.js-customer-menu') && this.submenu.classList.contains('displayed')) {
          this.toggleSubmenu();
        }
      });

      document.addEventListener('keyup', (event) => {
        if (event.defaultPrevented) {
          return;
        }

        // If the property have an undefined value, we'll look for keyCode.
        const key = event.key || event.keyCode;

        if ((key === 'Escape' || key === 'Esc' || key === 27) && this.submenu.classList.contains('displayed')) {
          this.toggleSubmenu();
        }
      });
    }
  }

  toggleSubmenu() {
    if (this.submenu.classList.contains('displayed')) {
      this.submenu.classList.remove('displayed');
    } else {
      this.submenu.classList.add('displayed');
    }
  }
}
