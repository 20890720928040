export class Axeptio {
  constructor() {
    this.waitForAxeptio();
  }

  waitForAxeptio() {
    const promise = new Promise(wait);

    function wait(resolve, reject) {
      const timeout = 5000;
      const start = Date.now();

      if (typeof _axcb !== 'undefined') {
        resolve();
      } else if (timeout && (Date.now() - start) >= timeout) {
        reject(new Error('Timeout'));
      } else {
        setTimeout(wait.bind(this, resolve, reject), 50);
      }
    }

    promise.then(() => {
      this.initContextualConsent();
      this.initHubspot();
      this.initRecaptcha();
    }).catch((error) => {
      console.log(`Not able to load Axeptio: ${error}`);
    });
  }

  initContextualConsent() {
    // Contextual consent https://developers.axeptio.eu/v/francais/integration/consentement-contextuel
    // -- Youtube -- Vimeo -- //
    const buttons = document.querySelectorAll('[data-hide-on-vendor-consent]');
    const iframes = document.querySelectorAll('[data-requires-vendor-consent]');

    (_axcb = window._axcb || []).push((sdk) => {
      sdk.on('cookies:complete', (choices) => {
        buttons.forEach(button => {
          const vendor = button.getAttribute('data-hide-on-vendor-consent');

          if (choices[vendor]) {
            button.classList.add('d-none');
          } else {
            button.classList.remove('d-none');
          }
        });

        iframes.forEach(iframe => {
          const vendor = iframe.getAttribute('data-requires-vendor-consent');
          if (choices[vendor]) {
            iframe.setAttribute('src', iframe.getAttribute('data-src'));
          }
        });
      });
    });
  }

  initHubspot() {
    // Script tag creation https://developers.axeptio.eu/v/francais/cookies/integration-des-cookies
    // -- Hubspot -- //
    const hubspotClientIdMeta = document.querySelector('meta[name="hubspot-client-id"]');
    const hubspotRegionIdMeta = document.querySelector('meta[name="hubspot-region"]');

    const buttons = document.querySelectorAll('[data-hide-on-vendor-consent]');
    const formElems = document.querySelectorAll('[data-requires-vendor-consent]');

    if (hubspotClientIdMeta && hubspotClientIdMeta.content && hubspotRegionIdMeta && hubspotRegionIdMeta.content) {
      this.clientId = hubspotClientIdMeta.content;
      this.regionId = hubspotRegionIdMeta.content;

      void 0 === window._axcb && (window._axcb = []);
      window._axcb.push((axeptio) => {
        axeptio.on('cookies:complete', (choices) => {
          buttons.forEach(button => {
            const vendor = button.getAttribute('data-hide-on-vendor-consent');

            if (choices[vendor]) {
              button.classList.add('d-none');
            } else {
              button.classList.remove('d-none');
            }
          });

          formElems.forEach(elem => {
            const vendor = elem.getAttribute('data-requires-vendor-consent');

            if (choices[vendor]) {
              elem.classList.remove('d-none');
            } else {
              elem.classList.add('d-none');
            }
          });

          if (choices.hubspot) {
            this.loadHubspot(() => this.onLoadedHubspot());
          }
        })
      });
    }
  }

  loadHubspot(callback) {
    const el = document.createElement('script');
    el.setAttribute('id', 'hs-script-loader');
    el.setAttribute('type', 'text/javascript');
    el.setAttribute('async', true);
    el.setAttribute('defer', true);
    el.setAttribute('src', `//js-${this.regionId}.hs-scripts.com/${this.clientId}.js`);

    if (callback) {
      el.onload = () => {
        callback();
      }
    }

    document.body.append(el);
  }

  onLoadedHubspot() {
    const forms = document.querySelectorAll('.js-axeptio-hubspot');

    if (forms.length > 0) {
      const el = document.createElement('script');
      el.async = true;
      el.defer = true;
      el.src = `//js-${this.regionId}.hsforms.net/forms/v2.js?pre=1`;

      el.onload = () => {
        forms.forEach((element) => {
          if (element.dataset.formId === undefined) {
            return;
          }

          const formScript = document.createElement('script');
          element.innerHTML = '';
          element.appendChild(formScript);
          formScript.innerHTML = `hbspt.forms.create({region: "${this.regionId}", portalId: "${this.clientId}", formId: "${element.dataset.formId}"});`;
          document.body.append(formScript);
        });
      };

      document.body.append(el);
    }
  }

  initRecaptcha() {
    const reCaptcha = document.querySelector('.g-recaptcha');
    const template = document.createElement('template');
    let html = `<button type="button" class="btn btn-primary btn-small" onclick="window.axeptioSDK.requestConsent('GoogleRecaptcha')">
                Accept Google reCaptcha cookie
              </button>`;
    html = html.trim();
    template.innerHTML = html;

    const button = template.content.firstChild;

    (_axcb = window._axcb || []).push((sdk) => {
      sdk.on('cookies:complete', (choices) => {
        if (reCaptcha) {
          if (!choices.GoogleRecaptcha) {
            reCaptcha.parentNode.insertBefore(button, reCaptcha);
          } else {
            button.remove();
          }
        }
      });
    });
  }
}
