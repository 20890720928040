const axios = require('axios').default;

export class Favorites {
  constructor() {
    this.favoriteWrapper = document.querySelectorAll('.js-favorites-wrapper');

    if (this.favoriteWrapper.length) {
      this.init();
    }
  }

  init() {
    this.favoriteWrapper.forEach((wrapper) => {
      wrapper.parentNode.addEventListener('click', (e) => {
        if (e.target.classList.contains('js-favorite-trigger') || e.target.closest('.js-favorite-trigger')) {
          const wrapper = e.target.closest('.js-favorites-wrapper');
          const element = e.target.closest('.js-favorite-trigger');

          this.doRequest(wrapper, element);
        }
      });
    });
  }

  doRequest(wrapper, element) {
    const { elementId } = element.dataset;
    const { favoritesType } = wrapper.dataset;
    const { favoritesUrl } = wrapper.dataset;

    const elementIsFavorited = element.classList.contains('is-favorited');

    let params = null;

    if (favoritesType === 'product') {
      // Product
      const { attributeId } = element.dataset;

      params = {
        operation: elementIsFavorited ? 'remove' : 'add',
        id_product: elementId,
        id_product_attribute: attributeId,
      };
    } else {
      // Recipe
      params = {
        operation: elementIsFavorited ? 'remove' : 'add',
        id_recipe: elementId,
      }
    }

    axios({
      method: 'POST',
      url: favoritesUrl,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      params,
    })
    .then((response) => {
      this.toggleIcon(element, elementIsFavorited);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  toggleIcon(element, elementIsFavorited) {
    if (elementIsFavorited) {
      element.classList.remove('is-favorited');
    } else {
      element.classList.add('is-favorited');
    }
  }
}
