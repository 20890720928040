export class Tables {
  constructor() {
    this.tableWrappers = [...document.querySelectorAll('.js-table-wrapper')];

    if (this.tableWrappers.length) {
      this.init();
    }
  }

  init() {
    this.tableWrappers.forEach((wrapper) => {
      const leftCells = [...wrapper.querySelectorAll('.js-cell-left')];
      const typeFilter = wrapper.querySelector('.js-type-filter');

      if (leftCells.length) {
        wrapper.addEventListener('scroll', () => {
          this.handleLeftColumn(wrapper, leftCells)
        });
      }

      if (typeFilter) {
        this.handleTypeFilter(wrapper, typeFilter);
      }
    });
  }

  handleLeftColumn(wrapper, leftCells) {
    if (leftCells.length) {
      if (wrapper.scrollLeft === 0) {
        leftCells.forEach((cell) => {
          cell.classList.remove('shadowed');
        });
      } else {
        leftCells.forEach((cell) => {
          cell.classList.add('shadowed');
        });
      }
    }
  }

  handleTypeFilter(wrapper, typeFilter) {
    const productRows = [...wrapper.querySelectorAll('.js-product-row')];
    let listArr = [];

    function formatText(string) {
      // Returns string to lowercase + ignore accents
      return string.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    productRows.forEach((row, index) => {
      listArr[index] = {
        row,
        productName: formatText(row.dataset.productName),
        productReference: formatText(row.dataset.productReference),
      }
    });

    typeFilter.addEventListener('input', () => {
      const inputString = formatText(typeFilter.value);

      listArr.forEach((item) => {
        if (!item.productName.includes(inputString) && !item.productReference.includes(inputString)) {
          item.row.style.display = 'none';
        } else {
          item.row.style.display = 'flex';
        }
      });
    });
  }
}
