export class CreditControlModal {
  constructor() {
    this.wrapper = document.querySelector('.js-credit-control');
    this.modal = document.querySelector('.js-credit-control-modal');

    if (this.wrapper && this.modal) {
      this.init();
    }
  }

  init() {
    $(this.modal).modal('show');
  }
}
