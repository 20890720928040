import $ from 'jquery';

export default class SlickSlider {
  init() {
    const chevronUrl = `${prestashop.urls.img_url}static/svg-defs.svg#arrow`;
    const chevronUrlSimple = `${prestashop.urls.img_url}static/svg-defs.svg#chevron-arrow`;

    const configDefault = {
      prevArrow: `<button aria-label="Slide précédente" type="button" class="btn btn-link slick-prev slick-arrow"><span><svg width="17" height="16" focusable="false"><use xlink:href="${chevronUrl}"></use></svg></span></button>`,
      nextArrow: `<button aria-label="Slide suivante" type="button" class="btn btn-link slick-next slick-arrow"><span><svg width="17" height="16" focusable="false"><use xlink:href="${chevronUrl}"></use></svg></span></button>`,
      customPaging: () => {
        return '<button aria-label="Changer de slide" class="btn btn-dot"><span></span></button>';
      },
    }

    const configFeaturedCategories = {
      prevArrow: `<button aria-label="Slide précédente" type="button" class="btn btn-link slick-prev slick-arrow"><span><svg width="17" height="16" focusable="false"><use xlink:href="${chevronUrl}"></use></svg></span></button>`,
      nextArrow: `<button aria-label="Slide suivante" type="button" class="btn btn-link slick-next slick-arrow"><span><svg width="17" height="16" focusable="false"><use xlink:href="${chevronUrl}"></use></svg></span></button>`,
      customPaging: () => {
        return '<button aria-label="Changer de slide" class="btn btn-dot"><span></span></button>';
      },
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      arrows: true,
      rows: 0,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        }
      ]
    }

    const configProductCover = {
      prevArrow: `<button aria-label="Slide précédente" type="button" class="btn btn-link slick-prev slick-arrow"><span><svg width="17" height="16" focusable="false"><use xlink:href="${chevronUrl}"></use></svg></span></button>`,
      nextArrow: `<button aria-label="Slide suivante" type="button" class="btn btn-link slick-next slick-arrow"><span><svg width="17" height="16" focusable="false"><use xlink:href="${chevronUrl}"></use></svg></span></button>`,
      asNavFor: '[data-slick].product-thumbs',
      rows: 0,
      slidesToShow: 1,
      arrows: false,
      adaptiveHeight: true,
      customPaging: () => {
        return '<button aria-label="Changer de slide" class="btn btn-dot"><span></span></button>';
      },
    }

    const configProductThumb = {
      prevArrow: `<button aria-label="Slide précédente" type="button" class="btn btn-link slick-prev slick-arrow"><span><svg width="17" height="16" focusable="false"><use xlink:href="${chevronUrlSimple}"></use></svg></span></button>`,
      nextArrow: `<button aria-label="Slide suivante" type="button" class="btn btn-link slick-next slick-arrow"><span><svg width="17" height="16" focusable="false"><use xlink:href="${chevronUrlSimple}"></use></svg></span></button>`,
      customPaging: () => {
        return '<button aria-label="Changer de slide" class="btn btn-dot"><span></span></button>';
      },
      asNavFor: '[data-slick].products-imagescover',
      slidesToShow: 5,
      slidesToScroll: 1,
      focusOnSelect: true,
      adaptiveHeight: true,
    }

    $('[data-slick]').not('.slick-initialized').each(function() {
      let self = $( this );
      if(self.data('count') === 1){
        return;
      }

      if (self.data('slick-config') === 'configFeaturedCategories') {
        self.slick(configFeaturedCategories);
      } else if (self.data('slick-config') === 'configProductCover') {
        self.slick(configProductCover);
      } else if (self.data('slick-config') === 'configProductThumb') {
        self.slick(configProductThumb);
      } else {
        self.slick(configDefault);
      }

      self.addClass('displayed');
    });
  }
}

