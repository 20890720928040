export class ButtonSpinner {
  constructor() {
    this.spinnerWrappers = document.querySelectorAll('.js-spinner-wrapper');

    if (this.spinnerWrappers.length) {
      this.init();
    }
  }

  init() {
    this.spinnerWrappers.forEach((wrapper) => {
      wrapper.addEventListener('click', (e) => {
        if (e.target.closest('.js-spinner-trigger')) {
          const triggers = wrapper.querySelectorAll('.js-spinner-trigger');
          const spinners = wrapper.querySelectorAll('.js-spinner-spin');

          this.displaySpinners(triggers, spinners);
        }
      });
    });

    prestashop.on('updateCart', () => {
      const triggers = document.querySelectorAll('.js-spinner-trigger');
      const spinners = document.querySelectorAll('.js-spinner-spin');
      this.hideSpinners(triggers, spinners);
    })
  }

  displaySpinners(triggers, spinners) {
    triggers.forEach((trigger) => {
      trigger.classList.add('d-none');
    });

    spinners.forEach((spinner) => {
      spinner.classList.remove('d-none');
    });
  }

  hideSpinners(triggers, spinners) {
    triggers.forEach((trigger) => {
      trigger.classList.remove('d-none');
    });

    spinners.forEach((spinner) => {
      spinner.classList.add('d-none');
    });
  }
}
