export class BsVideoModal {
  constructor() {
    this.videoModals = document.querySelectorAll('.js-bs-video-modal');

    if (this.videoModals.length) {
      this.callYoutubeApi();
      this.init();
    }
  }

  callYoutubeApi() {
    const tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  init() {
    this.videoModals.forEach((modal) => {
      let videoIsInstanciated = false;
      let player = modal.querySelector('.js-yt-player');
      const { videoId } = player.dataset;

      // On modal opening
      $(modal).on('show.bs.modal', () => {
        // If cookies accepted and video not instanciated yet, we instanciate the video
        if (window.tarteaucitron.state.youtubenovius && !videoIsInstanciated) {
          player = this.showVideo(player, videoId);
          videoIsInstanciated = true;
        // If cookies accepted and video is instanciated, we resume video play
        } else if (window.tarteaucitron.state.youtubenovius && videoIsInstanciated) {
          player.playVideo();
        // Else : Cookies are not accepted here, we have to handle the overlay
        } else {
          const overlay = modal.querySelector('.js-cookie-overlay');
          const enableYoutubeTrigger = modal.querySelector('.js-enable-youtube');

          if (overlay && enableYoutubeTrigger) {
            overlay.classList.add('displayed');

            enableYoutubeTrigger.addEventListener('click', () => {
              player = this.activateYoutube(player, videoId, overlay);
              videoIsInstanciated = true;
            });
          }
        }
      });

      // On modal closing
      $(modal).on('hidden.bs.modal', () => {
        if (videoIsInstanciated) {
          player.pauseVideo();
        }
      });
    });
  }

  showVideo(player, videoId) {
    player = new YT.Player(player, {
      height: '360',
      width: '640',
      videoId,
      events: {
        'onReady': (e) => e.target.playVideo(),
      }
    });

    return player;
  }

  activateYoutube(player, videoId, overlay) {
    this.updateTacCookie();
    window.tarteaucitron.state.youtubenovius = true;
    overlay.classList.remove('displayed');
    player = this.showVideo(player, videoId);

    return player;
  }

  updateTacCookie() {
    const setCookie = (cName, cValue, expDays) => {
      let date = new Date();
      date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
      const expires = 'expires=' + date.toUTCString();
      document.cookie = cName + '=' + cValue + '; ' + expires + '; path=/; sameSite=Lax;';
    }

    const cookies = document.cookie.split('; ');
    const tacCookieName = 'tarteaucitron=';
    const youtubeFalse = 'youtubenovius=false';

    cookies.forEach((cookie) => {
      if (cookie.includes(tacCookieName)) {
        let tacCookieValue = cookie.split(tacCookieName)[1];

        if (tacCookieValue.includes(youtubeFalse)) {
          tacCookieValue = tacCookieValue.replace(youtubeFalse, 'youtubenovius=true');

          setCookie('tarteaucitron', tacCookieValue, 365);
        }
      }
    });
  }
}
