export class DeliveryAddress {
  constructor() {
    this.wrapper = document.querySelector('.js-delivery-address-wrapper');

    if (this.wrapper) {
      this.init();
    }
  }

  init() {
    this.input = this.wrapper.querySelector('.js-delivery-address-input');
    this.triggers = [...this.wrapper.querySelectorAll('.js-delivery-address-trigger')];

    if (this.input && this.triggers.length) {
      this.bindEvents();
    }
  }

  bindEvents() {
    this.triggers.forEach((trigger) => {
      trigger.addEventListener('click', () => {
        this.resetDisplay();
        this.setAddress(trigger);
      });
    });
  }

  setAddress(trigger) {
    const { addressId } = trigger.dataset;
    const parentDiv = trigger.closest('.js-delivery-address-item');

    this.input.value = addressId;
    parentDiv.classList.add('selected');
  }

  resetDisplay() {
    this.triggers.forEach((trigger) => {
      const parentDiv = trigger.closest('.js-delivery-address-item');
      parentDiv.classList.remove('selected');
    });
  }
}
