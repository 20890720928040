import $ from 'jquery';

export class BtobCart {
  constructor() {
    this.BtobCartWrapper = document.querySelector('.js-btob-cart');

    if (this.BtobCartWrapper) {
      this.init();
    }
  }

  init() {
    this.createQuantitySpin();
  }

  createQuantitySpin() {
    this.inputs = this.BtobCartWrapper.querySelectorAll('.js-quantity-wanted');

    if (this.inputs.length) {
      this.inputs.forEach((input) => {
        $(input).TouchSpin({
          buttondown_class: 'btn js-touchspin',
          buttonup_class: 'btn js-touchspin',
          min: parseInt($(input).attr('min'), 10),
          max: 1000000,
          step: parseInt($(input).attr('step'), 10),
        });
      });
    }
  }
}
