import $ from 'jquery';
import axios from "axios";

export class ShoppingList {
  constructor() {
    this.shoppingListWrapper = document.querySelector('.js-customer-shopping');
    this.form = document.querySelector('.js-shopping-list-form');
    this.addToCartBtns = document.querySelectorAll('.js-add-shopping-list-to-cart');
    this.showHiddenProducts = document.querySelectorAll('.js-toggle-hidden');
    this.scrollBtn = document.querySelector('.js-shopping-scroll-btn');

    if (this.shoppingListWrapper && this.form && this.addToCartBtns.length) {
      this.init();
    }

    if (this.scrollBtn) {
      this.initScrollBtn();
    }
  }

  init() {
    this.createQuantitySpin();

    this.addToCartBtns.forEach((button) => {
      button.addEventListener('click', () => this.addToCart());
    });

    if (this.showHiddenProducts.length) {
      this.showHiddenProducts.forEach((element) => {
        element.addEventListener('click', (e) => this.toggleHiddenLines(e));
      });
    }

    this.form.addEventListener('click', (e) => {
      if (e.target.closest('.js-toggle-hidden')) {
        const row = e.target.closest('.js-product-row');
        e.preventDefault();

        let endpoint = this.shoppingListWrapper.dataset.urlAjax;

        axios.post(endpoint, {},{
          params: {
            ajax: 1,
            action: row.classList.contains('hidden-row') ? 'unhide' : 'hide',
            id_product: e.target.closest('.js-toggle-hidden').dataset.idProduct,
            id_product_attribute: e.target.closest('.js-toggle-hidden').dataset.idCombination,
          }
        }).then((response) => {
          row.classList.toggle('hidden-row');
        });
      }

      if (e.target.closest('.js-button-star')) {
        const star = e.target.closest('.js-button-star');
        e.preventDefault();

        let endpoint = this.shoppingListWrapper.dataset.urlAjax;

        axios.post(endpoint, {},{
          params: {
            ajax: 1,
            action: star.classList.contains('active') ? 'untop' : 'top',
            id_product: star.dataset.idProduct,
            id_product_attribute: star.dataset.idCombination,
          }
        }).then((response) => {
          star.classList.toggle('active');
        });
      }
    });
  }

  createQuantitySpin() {
    this.inputs = this.shoppingListWrapper.querySelectorAll('.js-quantity-wanted');

    if (this.inputs.length) {
      this.inputs.forEach((input) => {
        const $touchspin = $(input).TouchSpin({
          buttondown_class: 'btn js-touchspin',
          buttonup_class: 'btn js-touchspin',
          min: 0,
          max: 1000000,
          step: parseInt($(input).attr('step'), 10),
        });

        const orderMin = parseInt($(input).data('min'), 10);
        const touchspin = $touchspin[0];

        $touchspin.on('touchspin.on.startupspin', () => {
          if (parseInt(touchspin.value, 10) < orderMin) {
            touchspin.value = orderMin;
          }
        });

        $touchspin.on('touchspin.on.startdownspin', () => {
          if (parseInt($touchspin.val(), 10) < orderMin) {
            touchspin.value = 0;
          }
        });

        $touchspin.on('focusout', () => {
          if (parseInt($touchspin.val(), 10) < orderMin && $touchspin.val() !== '0') {
            touchspin.value = orderMin;
          }
        });

        prestashop.on('updateCart', () => {
          touchspin.value = 0;
        });
      });
    }
  }

  addToCart() {
    this.inputs = this.shoppingListWrapper.querySelectorAll('.js-quantity-wanted');
    let addToCartData = [];

    if (this.inputs.length) {
      this.inputs.forEach((input) => {
        if (Number(input.value) > 0) {
          addToCartData.push({
            id_product: input.dataset.idProduct,
            id_product_attribute: input.dataset.idProductAttribute,
            quantity: input.value
          });
        }
      });
    }

    if (addToCartData.length < 1) {
      const modal = document.querySelector('.js-shop-add-to-cart-no-product');
      this.displayModal(modal);

      return;
    }

    axios.post(this.form.getAttribute('action'), addToCartData)
      .then((response) => {
        if (addToCartData.length) {
          const modal = document.querySelector('.js-shop-add-to-cart-success');
          this.displayModal(modal);

          prestashop.emit('updateCart', {
            reason: addToCartData,
            resp: response,
          });
        } else {
          const modal = document.querySelector('.js-shop-add-to-cart-no-product');
          this.displayModal(modal);
        }
      })
      .catch((error) => {
        console.log(error);
        const modal = document.querySelector('.js-shop-add-to-cart-error');
        this.displayModal(modal);
      });
  }

  displayModal(modal) {
    $(modal).modal('show');
  }

  toggleHiddenLines(event) {
    const table = event.target.closest('.js-card');

    if (table) {
      table.querySelectorAll('.js-product-row[data-hidden="true"]')
        .forEach((elem) => elem.classList.toggle('hidden-row'));
    }
  }

  initScrollBtn() {
    this.scrollBottomTrigger = this.scrollBtn.querySelector('.js-shopping-scroll-bottom');
    this.scrollTopTrigger = document.querySelector('.js-scroll-top');
    this.anchorBottom = document.querySelector('.js-anchor-bottom');

    this.bindScrollEvents();
    this.displayProperButton();
  }

  bindScrollEvents() {
    this.scrollBottomTrigger.addEventListener('click', () => {
      this.anchorBottom.scrollIntoView({ behavior: 'smooth' });
    });

    window.addEventListener('scroll', () => {
      setTimeout(() => {
        this.displayProperButton();
      }, 100);
    });

    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.displayProperButton();
      }, 100);
    });
  }

  displayProperButton() {
    if (window.innerWidth <= 991) {
      const scrollPosition = window.scrollY;
      const bodyHeight = document.body.offsetHeight;
      const footerHeight = document.querySelector('#footer').offsetHeight;
      const windowHeight = window.innerHeight;

      if (scrollPosition < bodyHeight - footerHeight - windowHeight) {
        this.scrollTopTrigger.classList.add('d-none');
        this.scrollBottomTrigger.classList.remove('d-none');
      } else if (scrollPosition) {
        this.scrollTopTrigger.classList.remove('d-none');
        this.scrollBottomTrigger.classList.add('d-none');
      }
    }
  }
}
