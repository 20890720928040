import prestashop from 'prestashop';

export class StoreGeoloc {
  constructor() {
    this.mapContainer = document.querySelector('.js-store-geoloc');

    if (this.mapContainer) {
      this.init();
    }
  }

  init() {
    this.geolocEndpoint = this.mapContainer.dataset.endpointUrl;
    this.googleApiScript = this.mapContainer.dataset.googleApiScript;
    this.mapElement = this.mapContainer.querySelector('.js-gmap');
    this.inputAddrSearch = document.querySelector('.js-map-address-search');
    this.template = document.querySelector('.js-map-info-template').innerHTML;

    this.injectScriptTag();
  }

  injectScriptTag() {
    const scriptTag = document.createElement('script');
    scriptTag.setAttribute('src', this.googleApiScript);
    document.head.appendChild(scriptTag);
  }

  initMap() {
    this.gmap = new google.maps.Map(this.mapElement, {
      zoom: 6,
      center: {lat: 48.8534, lng: 2.3488},
    });

    this.infoWindow = new google.maps.InfoWindow();

    this.originMarker = new google.maps.Marker({map: this.gmap});
    this.originMarker.setVisible(false);

    this.gmap.data.loadGeoJson(this.geolocEndpoint, {idPropertyName: 'id_store'});

    this.gmap.data.addListener('click', (event) => this.onStoreClick(event.feature));

    // Autocomplete init
    const options = {
      componentRestrictions: {country: 'fr'},
    };

    this.autocomplete = new google.maps.places.Autocomplete(this.inputAddrSearch, options);
    this.autocomplete.setFields(['address_components', 'geometry', 'name']);
    this.autocomplete.addListener('place_changed', () => this.onPlaceChange());
  }

  onPlaceChange() {
    this.originMarker.setVisible(false);
    let originLocation = this.gmap.getCenter();
    const place = this.autocomplete.getPlace();

    if (place && place.geometry) {
      // Recenter the map to the selected address
      originLocation = place.geometry.location;
      this.gmap.setCenter(originLocation);
      this.gmap.setZoom(13);

      this.originMarker.setPosition(originLocation);
      this.originMarker.setVisible(true);
    }
  }

  onStoreClick(marker) {
    const shop = {
      type: marker.getProperty('label_type'),
      full_address: marker.getProperty('full_address'),
      name: marker.getProperty('name'),
      description: marker.getProperty('description'),
      hours: marker.getProperty('hours'),
      phone: marker.getProperty('phone'),
    };

    const position = marker.getGeometry().get();
    const content = new Function('shop', 'return `' + this.template + '`;')(shop);

    this.infoWindow.setContent(content);
    this.infoWindow.setPosition(position);
    this.infoWindow.setOptions({pixelOffset: new google.maps.Size(0, -30)});
    this.infoWindow.open(this.gmap);
  }
}
